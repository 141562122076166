<template>
  <div>
    <el-upload
      class="upload-demo"
      action
      drag
      :auto-upload="false"
      :show-file-list="false"
      :on-change="changeUpload"
      :limit='10'
    >
      <i class="iconfont iconadd"></i>
    </el-upload>
    <!-- <input
      type="file"
      id="uploads"
      :value="imgFile"
      accept="image/png, image/jpeg, image/gif, image/jpg"
      @change="uploadImg($event, 1)" /> -->

    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body class="dial">
      <div class="cropper-content">
        <div class="cropper" style="text-align:center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.size"
            :outputType="option.outputType"
            :info="true"
            :full="option.full"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :autoCrop="option.autoCrop"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"
            @realTime="realTime"
          ></vueCropper>
        </div>
        <div class="show-preview" 
        :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden',
							'margin': '5px'}">
						<div :style="previews.div">
							<img :src="previews.url" :style="previews.img">
						</div>
				</div>
        
      </div>
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { VueCropper } from "vue-cropper";
//import { client } from 'ali-oss'
export default {
  props: {
    ffixed: {
      set: Boolean,
      required: false
    },
    ffixedNumber: {
      required: false
    }
  },
  data() {
    return {
      cropperImg: "",
      dialogVisible: false,
      previews: {},
      imgFile:'',
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: this.ffixed ||true, // 是否开启截图框宽高固定比例
        fixedNumber: this.ffixedNumber || [7,5], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      picsList: [], //页面显示的数组
      // 防止重复提交
      loading: false,
      fileList:[],
    };
  },
  created() {
  },
  computed: {},
  components: {
    VueCropper
  },
  methods: {
    changeUpload(file, fileList) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        return false;
      }
      this.fileinfo = file;
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.dialogVisible = true;
        var that = this
        var reader = new FileReader() // 这里使用FileReader() 来得到图片地址
        reader.onload = function(e) {
            that.option.img = e.target.result
        }
        reader.readAsDataURL(file.raw)

        });


      
    },
    finish() {
      this.$refs.cropper.getCropBlob(data => {
        // console.log(data)
      let img = window.URL.createObjectURL(data)
      this.blobToDataURL(data)
      // console.log(this.getRes)
        // console.log(data)
        // this.$emit('change', this.previews)
        
        // this.loading = true;
        this.dialogVisible=false
      });
    },
     blobToDataURL(blob) {
       var _this=this
        var a = new FileReader();
         a.readAsDataURL(blob);//读取文件保存在result中
        a.onload = function (e) {
        var getRes = e.target.result;//读取的结果在result中
         _this.$emit('change', getRes,blob) //传给父级的参数
         return getRes
        }
    },
    realTime(data) { 
        this.previews = data 
      },
    uploadImg(e, num) {
      var _this = this;
      //上传图片
      var file = e.target.files[0];
      _this.fileName = file.name;
      console.log(file)
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("图片类型必须是.gif,jpeg,jpg,png,bmp中的一种");
        return false;
      }
      var reader = new FileReader();
      reader.onload = e => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        if (num === 1) {
          _this.option.img = data;
        } else if (num === 2) {
          _this.example2.img = data;
        }
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },
  },
};
</script>
<style lang="less" scoped >
// 截图
.dial /deep/.el-dialog{
  width:60%;
}
.cropper-content {
  overflow: hidden;
  .cropper {
    width: 49%;
    height: 250px;
    min-height: 250px;
    float: left;
  }
  .show-preview{
    overflow: hidden;
        margin: 0 auto!important;
  }
}
/deep/.el-upload-dragger{
      width: 100px;
    height: 100px;
   background: none;
   .iconfont{
     font-size: 30px;
     line-height: 100px;
     color: #888;
   }
}
</style>